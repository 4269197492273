import {
    endOfMonth,
    endOfQuarter,
    endOfYear,
    monthsToQuarters,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subMonths,
    subQuarters,
    subYears,
} from 'date-fns';
import { DateTime } from 'luxon';
import vueI18n from '@/plugins/VueI18n';

const serverTimezone = 'UTC';
const clientTimezone = 'local';
export function formatDate(date: Date | string, format: string, locale = 'local'): string {
    const dateTime =
        date instanceof Date
            ? DateTime.fromJSDate(date, { zone: serverTimezone })
            : DateTime.fromISO(date, { zone: serverTimezone });

    return dateTime.setZone(clientTimezone).toFormat(format, {
        locale,
    });
}

export function formatWithTime(date: Date | string, locale = 'local'): string {
    return formatDate(date, 'ff', locale);
}

export function formatDateOnly(date: Date | string, locale = 'local'): string {
    return formatDate(date, 'DD', locale);
}

export function startOfQuarterByNumber(quarter: number): Date {
    const today = new Date();
    const startFullQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1);

    return startFullQuarter;
}

export function datePickerPresets() {
    return [
        { label: vueI18n.global.t('today'), value: [new Date(), new Date()] },
        { label: vueI18n.global.t('this-month'), value: [startOfMonth(new Date()), endOfMonth(new Date())] },
        { label: vueI18n.global.t('this-quarter'), value: [startOfQuarter(new Date()), endOfQuarter(new Date())] },
        { label: vueI18n.global.t('this-year'), value: [startOfYear(new Date()), endOfYear(new Date())] },
        {
            label: vueI18n.global.t('sub-month'),
            value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        {
            label: vueI18n.global.t('sub-quarter'),
            value: [startOfQuarter(subQuarters(new Date(), 1)), endOfQuarter(subQuarters(new Date(), 1))],
        },
        {
            label: vueI18n.global.t('sub-year'),
            value: [startOfYear(subYears(new Date(), 1)), startOfYear(subYears(new Date(), 1))],
        },
        {
            label: vueI18n.global.t('quarter1'),
            value: [startOfQuarter(startOfQuarterByNumber(1)), endOfQuarter(startOfQuarterByNumber(1))],
        },
        {
            label: vueI18n.global.t('quarter2'),
            value: [startOfQuarter(startOfQuarterByNumber(2)), endOfQuarter(startOfQuarterByNumber(2))],
        },
        {
            label: vueI18n.global.t('quarter3'),
            value: [startOfQuarter(startOfQuarterByNumber(3)), endOfQuarter(startOfQuarterByNumber(3))],
        },
        {
            label: vueI18n.global.t('quarter4'),
            value: [startOfQuarter(startOfQuarterByNumber(4)), endOfQuarter(startOfQuarterByNumber(4))],
        },
    ];
}
