import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }
const _hoisted_2 = ["itemKey"]
const _hoisted_3 = {
  key: 0,
  class: "column column--add"
}
const _hoisted_4 = { class: "column__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VPageSettings = _resolveComponent("VPageSettings")!
  const _component_VTaskFilter = _resolveComponent("VTaskFilter")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VTaskCard = _resolveComponent("VTaskCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VDraggable = _resolveComponent("VDraggable")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_VBoard = _resolveComponent("VBoard")!
  const _component_VTableRow = _resolveComponent("VTableRow")!
  const _component_VTableDataSet = _resolveComponent("VTableDataSet")!
  const _component_VTable = _resolveComponent("VTable")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, {
    class: _normalizeClass({ 'app-page--fullscreen': _ctx.pageSettings.fullscreen })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        "show-third-block": "",
        title: _ctx.pageTitle
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VPageSettings, {
              class: "prevent-close",
              options: _ctx.pageOptions,
              modelValue: _ctx.pageSettings,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSettings) = $event))
            }, null, 8, ["options", "modelValue"]),
            _createVNode(_component_VTaskFilter, {
              class: "prevent-close",
              "filter-types": [
                            _ctx.TaskFilterTypes.Author,
                            _ctx.TaskFilterTypes.Status,
                            _ctx.TaskFilterTypes.Assignee,
                            _ctx.TaskFilterTypes.Approver,
                            _ctx.TaskFilterTypes.Contributor,
                            _ctx.TaskFilterTypes.AuthorOrAssignee,
                            _ctx.TaskFilterTypes.Deadline,
                            _ctx.TaskFilterTypes.CreationDate,
                        ],
              onChanged: _ctx.fetchData,
              modelValue: _ctx.filters,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event))
            }, null, 8, ["filter-types", "onChanged", "modelValue"]),
            _createVNode(_component_VButtonDropdown, {
              class: "prevent-close",
              placement: "left-start",
              options: _ctx.regularActions
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DotsSvg, { class: "rotate-90" })
              ]),
              _: 1
            }, 8, ["options"]),
            _createVNode(_component_VSearchField, {
              class: "prevent-close",
              "debounce-mode": "",
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchString) = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, {
        vertical: _ctx.pageSettings.viewMode === 'table'
      }, {
        default: _withCtx(() => [
          (_ctx.pageSettings.viewMode === 'board')
            ? (_openBlock(), _createBlock(_component_VBoard, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                    return (_openBlock(), _createBlock(_component_VColumn, {
                      key: columnIndex,
                      "wheel-propagation": "",
                      column: column,
                      editable: _ctx.canUpdateColumnTitle(column),
                      onSetTitle: ($event: any) => (_ctx.setColumnTitle(column, $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VDraggable, {
                          tag: "ul",
                          class: "column__list",
                          group: "tasks",
                          draggable: ".column__item",
                          "ghost-class": "task-card-ghost",
                          "item-key": "id",
                          "scroll-sensitivity": 200,
                          onAdd: ($event: any) => (_ctx.onMove(column, $event)),
                          onUpdate: ($event: any) => (_ctx.onMove(column, $event)),
                          modelValue: _ctx.groupedTasks[column.id],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.groupedTasks[column.id]) = $event)
                        }, {
                          item: _withCtx(({ element }) => [
                            (_openBlock(), _createElementBlock("li", {
                              class: "column__item",
                              itemKey: element.id,
                              key: element.id
                            }, [
                              _createVNode(_component_RouterLink, {
                                to: { query: { task: element.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_VTaskCard, { task: element }, null, 8, ["task"])
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ], 8, _hoisted_2))
                          ]),
                          _: 2
                        }, 1032, ["onAdd", "onUpdate", "modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["column", "editable", "onSetTitle"]))
                  }), 128)),
                  (_ctx.canCreateColumn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_VButton, {
                            class: "button--link-underline",
                            onClick: _ctx.createColumn
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('add-new-column')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : (_ctx.pageSettings.viewMode === 'table')
              ? (_openBlock(), _createBlock(_component_VTable, {
                  key: 1,
                  resetOrder: _ctx.resetOrder,
                  table: _ctx.table,
                  "onUpdate:table": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.table) = $event)),
                  order: _ctx.order,
                  "onUpdate:order": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.order) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                      return (_openBlock(), _createBlock(_component_VTableDataSet, {
                        key: columnIndex,
                        title: column.title
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_VDraggable, {
                            tag: "div",
                            group: "tasks",
                            draggable: ".movable-item",
                            "item-key": "id",
                            "scroll-sensitivity": 200,
                            onAdd: ($event: any) => (_ctx.onMove(column, $event)),
                            onUpdate: ($event: any) => (_ctx.onMove(column, $event)),
                            modelValue: _ctx.groupedTasks[column.id],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.groupedTasks[column.id]) = $event)
                          }, {
                            item: _withCtx(({ element, index }) => [
                              _createVNode(_component_RouterLink, {
                                class: _normalizeClass(["prevent-close", { 'movable-item': _ctx.order.orderBy === 'rank' }]),
                                to: { query: { task: element.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_VTableRow, {
                                    table: _ctx.table,
                                    data: element,
                                    index: index,
                                    active: element.id.toString() == _ctx.route.query.task,
                                    onContextmenu: ($event: any) => (_ctx.openContextMenu($event, element))
                                  }, null, 8, ["table", "data", "index", "active", "onContextmenu"])
                                ]),
                                _: 2
                              }, 1032, ["class", "to"])
                            ]),
                            _: 2
                          }, 1032, ["onAdd", "onUpdate", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["title"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["resetOrder", "table", "order"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["vertical"])
    ]),
    _: 1
  }, 8, ["class"]))
}