import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }
const _hoisted_2 = { class: "column__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VPageSettings = _resolveComponent("VPageSettings")!
  const _component_VTaskFilter = _resolveComponent("VTaskFilter")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VGoalCard = _resolveComponent("VGoalCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VBoard = _resolveComponent("VBoard")!
  const _component_VTableRow = _resolveComponent("VTableRow")!
  const _component_VTableDataSet = _resolveComponent("VTableDataSet")!
  const _component_VTable = _resolveComponent("VTable")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_ctx.goals)
    ? (_openBlock(), _createBlock(_component_VPage, {
        key: 0,
        class: _normalizeClass({ 'app-page--fullscreen': _ctx.pageSettings.fullscreen })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VPageHeader, {
            "show-third-block": "",
            title: _ctx.t('my-goals')
          }, {
            second: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_VPageSettings, {
                  class: "prevent-close",
                  options: _ctx.pageOptions,
                  modelValue: _ctx.pageSettings,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSettings) = $event))
                }, null, 8, ["options", "modelValue"]),
                _createVNode(_component_VTaskFilter, {
                  class: "prevent-close",
                  "filter-types": [
                            _ctx.TaskFilterTypes.Author,
                            _ctx.TaskFilterTypes.Status,
                            _ctx.TaskFilterTypes.Assignee,
                            _ctx.TaskFilterTypes.Approver,
                            _ctx.TaskFilterTypes.Contributor,
                            _ctx.TaskFilterTypes.AuthorOrAssignee,
                            _ctx.TaskFilterTypes.Deadline,
                            _ctx.TaskFilterTypes.CreationDate,
                        ],
                  modelValue: _ctx.filters,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event))
                }, null, 8, ["filter-types", "modelValue"]),
                _createVNode(_component_VButtonDropdown, {
                  class: "prevent-close",
                  placement: "left-start",
                  options: _ctx.regularActions
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DotsSvg, { class: "rotate-90" })
                  ]),
                  _: 1
                }, 8, ["options"]),
                _createVNode(_component_VSearchField, {
                  class: "prevent-close",
                  "debounce-mode": "",
                  modelValue: _ctx.searchString,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchString) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_VPageContent, { vertical: "" }, {
            default: _withCtx(() => [
              (_ctx.pageSettings.viewMode === 'board')
                ? (_openBlock(), _createBlock(_component_VBoard, {
                    key: 0,
                    center: "",
                    vertical: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: columnIndex }, [
                          (column.projectId === -1 || _ctx.groupedGoals[column.boardId]?.length)
                            ? (_openBlock(), _createBlock(_component_VColumn, {
                                key: 0,
                                blurry: "",
                                "no-scrolling": "",
                                class: "column--goals column--shrink column--padding-bottom",
                                column: column
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("ul", _hoisted_2, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedGoals[column.boardId], (goal) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: goal.id,
                                        class: "column__item"
                                      }, [
                                        _createVNode(_component_RouterLink, {
                                          to: { name: 'goals.view', params: { goalId: goal.id } }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_VGoalCard, { goal: goal }, null, 8, ["goal"])
                                          ]),
                                          _: 2
                                        }, 1032, ["to"])
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["column"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : (_ctx.pageSettings.viewMode === 'table')
                  ? (_openBlock(), _createBlock(_component_VTable, {
                      key: 1,
                      resetOrder: _ctx.resetOrder,
                      table: _ctx.table,
                      "onUpdate:table": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.table) = $event)),
                      order: _ctx.order,
                      "onUpdate:order": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.order) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: columnIndex }, [
                            (_ctx.groupedGoals[column.boardId].length)
                              ? (_openBlock(), _createBlock(_component_VTableDataSet, {
                                  key: 0,
                                  title: column.title
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedGoals[column.boardId], (goal, index) => {
                                      return (_openBlock(), _createBlock(_component_RouterLink, {
                                        key: goal.id,
                                        class: "prevent-close",
                                        to: { name: 'goals.view', params: { goalId: goal.id } }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_VTableRow, {
                                            table: _ctx.table,
                                            data: goal,
                                            index: index,
                                            active: goal.id.toString() == _ctx.route.query.task,
                                            onContextmenu: ($event: any) => (_ctx.openContextMenu($event, goal))
                                          }, null, 8, ["table", "data", "index", "active", "onContextmenu"])
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["title"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["resetOrder", "table", "order"]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}