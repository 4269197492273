import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "file-list" }
const _hoisted_2 = {
  key: 0,
  class: "file-list__item"
}
const _hoisted_3 = { class: "attachment attachment--upload" }
const _hoisted_4 = {
  key: 0,
  class: "attachment__hint"
}

import PlusSvg from '@/assets/plus.svg';

// Components
import VAttachment from './VAttachment.vue';

// Other
import IAttachment from '@/core/Values/IAttachment';
import IUploader from '@/core/Uploader/IUploader';
import UploadableFile from '@/core/Uploader/UploadableFile';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { filesize } from 'filesize';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import { PropType, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VFileList',
  props: {
    uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
    attachments: { type: Array as PropType<IAttachment[]>, required: true, default: () => [] },
    uploadables: {
        type: Array as PropType<UploadableFile<IApiResult<IObjectStoreModel>>[]>,
        default: () => [],
    },
    queue: { type: Array as PropType<File[]>, default: () => [] },
    nowrap: { type: Boolean, default: false },
    hideAddButton: { type: Boolean, default: false },
},
  emits: ['uploaded', 'removed'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

let input = ref(null as HTMLInputElement | undefined | null);
let queue = ref(props.queue);
let uploadables = ref(props.uploadables);
let attachments = ref(props.attachments);

const fileSizeLimitString = computed(() => {
    if (!props.uploader.fileSizeLimit) {
        return '';
    }

    return filesize(props.uploader.fileSizeLimit, { standard: 'jedec' });
});

watch(
    () => props.queue,
    () => {
        queue.value = props.queue;
    },
);

watch(
    () => props.uploadables,
    () => {
        uploadables.value = props.uploadables;
    },
);

watch(
    () => props.attachments,
    () => {
        attachments.value = props.attachments;
    },
);

watch(
    () => queue.value,
    () => {
        if (queue.value?.length === 0) {
            return;
        }

        let file = queue.value.pop();
        while (file) {
            const uploadable = ref(new UploadableFile<IApiResult<IObjectStoreModel>>(file));

            uploadables.value.push(uploadable.value);
            props.uploader
                .upload(uploadable.value)
                .then((uploadable) => {
                    if (uploadable.status === UploadableFileStatus.UPLOADED) {
                        const index = uploadables.value.indexOf(uploadable);

                        if (index !== -1) {
                            uploadables.value.splice(index, 1);
                        }

                        emit('uploaded', uploadable);
                    }
                })
                .catch(() => null);

            // eslint-disable-next-line vue/no-mutating-props
            file = queue.value.pop();
        }
    },
    { deep: true },
);

function openFileSelectionWindow() {
    if (input.value) {
        input.value.click();
        input.value.value = '';
    }
}

function fileSelectedHandler() {
    if (input && input.value?.files) {
        for (let index = 0; index < input.value.files.length; index++) {
            const file = input.value.files.item(index);

            if (!file) {
                continue;
            }

            queue.value.push(file);
        }
    }
}

function abortUploading(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>) {
    uploadable.controller?.abort();

    const index = uploadables.value.indexOf(uploadable);
    if (index !== -1) {
        uploadables.value.splice(index, 1);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(attachments), (attachment, attachmentIndex) => {
      return (_openBlock(), _createElementBlock("li", {
        key: attachmentIndex,
        class: "file-list__item"
      }, [
        _createVNode(VAttachment, {
          nowrap: "",
          removable: "",
          attachment: attachment,
          onRemoved: ($event: any) => (_ctx.$emit('removed', attachment))
        }, null, 8, ["attachment", "onRemoved"])
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(uploadables), (uploadable, uploadableIndex) => {
      return (_openBlock(), _createElementBlock("li", {
        key: uploadableIndex,
        class: "file-list__item"
      }, [
        _createVNode(VAttachment, {
          nowrap: "",
          removable: "",
          uploadable: uploadable,
          onRemoved: ($event: any) => (abortUploading(uploadable))
        }, null, 8, ["uploadable", "onRemoved"])
      ]))
    }), 128)),
    (!__props.hideAddButton)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(PlusSvg)),
            (fileSizeLimitString.value)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_unref(t)('file-size-limit-hint', { limit: fileSizeLimitString.value })), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "attachment__button attachment__button--borderless attachment__button--upload",
              onClick: openFileSelectionWindow
            }),
            _createElementVNode("input", {
              ref_key: "input",
              ref: input,
              type: "file",
              hidden: "",
              onChange: fileSelectedHandler
            }, null, 544)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})