// Svg
import OpenSvg from '@/assets/open.svg';
import EditSvg from '@/assets/edit.svg';
import CheckSvg from '@/assets/check.svg';
import CompleteSvg from '@/assets/complete.svg';
import TimesSvg from '@/assets/times.svg';
import RollbackSvg from '@/assets/rollback.svg';
import ShareSvg from '@/assets/share.svg';
import ArchiveSvg from '@/assets/archive.svg';
import NotesCheckedSvg from '@/assets/notes-checked.svg';

// Other
import Operations from '@/core/Authorization/Operations';
import ITask from '@/core/Models/ITask';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import Status from '@/core/Values/Status';
import { h, markRaw, ref, computed, Ref } from 'vue';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import { $confirm, $error } from '@/utils/app-utils';
import TaskType from '@/core/Values/TaskType';
import IChatComment from '@/core/Values/IChatComment';
import IStory from '@/core/Models/IStory';
import StoryType from '@/core/Values/StoryType';
import { copyTextToClipboard, findLast } from '@/utils/utils';
import store from '@/store';
import IAttachment from '@/core/Values/IAttachment';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import ConfirmContext from '@/core/Values/ConfirmContext';
import ApprovementStatus from '@/core/Values/ApprovementStatus';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import Storages from '@/core/Storages';
import StoryViewMode from '@/core/Values/StoryViewMode';
import Settings from '@/core/Settings';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import { MenuItem } from '@imengyu/vue3-context-menu';
import { useI18n } from 'vue-i18n';
import ContextMenu from '@imengyu/vue3-context-menu';
import vueI18n from '@/plugins/VueI18n';
import TaskService from '@/core/Services/TaskService';
import IUser from '@/core/Models/IUser';
import IApprovement from '@/core/Models/IApprovement';
import IUploader from '@/core/Uploader/IUploader';

type CollaboratorEvent = { user: IUser; role: CollaboratorRole };
type ReplaceCollaboratorEvent = { oldCollaborator: CollaboratorEvent; newCollaborator: CollaboratorEvent };

interface ITaskAction extends IDropdownOption {
    show: boolean;
    panelClasses?: string;
    isMainAction: boolean;
}

const api = {
    async changeTitleAsync(task: ITask, newTitle: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setTitleAsync(task.id, newTitle);
        } catch (error) {
            $error(error);
        }
    },

    async changeDeadlineAsync(task: ITask, newDeadline: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setDeadlineAsync(task.id, newDeadline);
        } catch (error) {
            $error(error);
        }
    },

    async changeDescriptionAsync(task: ITask, newDescription: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setDescriptionAsync(task.id, newDescription);
        } catch (error) {
            $error(error);
        }
    },

    async changeAuthorAsync(task: ITask, newAuthorId: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setAuthorAsync(task.id, newAuthorId);
        } catch (error) {
            $error(error);
        }
    },

    async createCommentAsync(task: ITask, comment: IChatComment) {
        if (!task) {
            return;
        }

        try {
            await TaskService.createCommentAsync(task.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async updateCommentAsync(task: ITask, commentId: number, newText: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.updateCommentAsync(task.id, commentId, newText);
        } catch (error) {
            $error(error);
        }
    },

    async createAttachmentAsync(task: ITask, attachment: IAttachment) {
        if (!task) {
            return;
        }

        try {
            await TaskService.createAttachmentAsync(task.id, attachment.objectName, attachment.fileName);
        } catch (error) {
            $error(error);
        }
    },

    async removeStoryAsync(task: ITask, story: IStory) {
        if (!task) {
            return;
        }

        try {
            await TaskService.deleteStoryAsync(task.id, story.id);
        } catch (error) {
            $error(error);
        }
    },

    async addCollaboratorAsync(task: ITask, { user, role }: CollaboratorEvent) {
        if (!task) {
            return;
        }

        try {
            await TaskService.addCollaboratorAsync(task.id, user.id, role);
        } catch (error) {
            $error(error);
        }
    },

    async removeCollaboratorAsync(task: ITask, { user, role }: CollaboratorEvent) {
        if (!task) {
            return;
        }

        try {
            await TaskService.removeCollaboratorAsync(task.id, user.id, role);
        } catch (error) {
            $error(error);
        }
    },

    async replaceCollaboratorAsync(task: ITask, event: ReplaceCollaboratorEvent) {
        if (!task) {
            return;
        }

        try {
            await TaskService.replaceCollaboratorAsync(
                task.id,
                event.oldCollaborator.user.id,
                event.newCollaborator.user.id,
                event.newCollaborator.role,
            );
        } catch (error) {
            $error(error);
        }
    },

    async startApprovementProcessAsync(task: ITask, approverIds: string[]) {
        if (!task) {
            return;
        }

        try {
            await TaskService.startApprovementProcessAsync(task.id, approverIds);
        } catch (error) {
            $error(error);
        }
    },

    async completeAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.completeAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async finishAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.finishAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async toDraftAsync(task: ITask, event?: { callback?: () => void }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.toDraftAsync(task.id);
            if (event?.callback) {
                event.callback();
            }
        } catch (error) {
            $error(error);
        }
    },

    async toInProgressAsync(task: ITask, comment?: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.toInProgressAsync(task.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async approveAsync(task: ITask, event: { approvement: IApprovement; comment?: string }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.approveAsync(task.id, event.approvement.id, event.comment);
        } catch (error) {
            $error(error);
        }
    },

    async disapproveAsync(task: ITask, { approvement, comment }: { approvement: IApprovement; comment?: string }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.disapproveAsync(task.id, approvement.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async archiveAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.archiveAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async rollbackChangesAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.rollbackChangesAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async createSubtaskAsync(task: ITask): Promise<void> {
        if (!task) {
            return;
        }

        try {
            await TaskService.createAsync(TaskType.Subtask, task.id);
        } catch (error) {
            $error(error);
        }
    },

    async changeParentTaskAsync(task: ITask, parentId: number): Promise<void> {
        if (!task) {
            return;
        }

        try {
            await TaskService.setParentAsync(task.id, parentId);
        } catch (error) {
            $error(error);
        }
    },
};

export function getTaskTypeString(task: ITask) {
    return task.type === TaskType.Goal ? 'goal' : 'task';
}

export function getTaskRouteLocation(task: ITask): RouteLocationRaw {
    if (task.type === TaskType.Goal) {
        return {
            name: 'goals.view',
            params: {
                goalId: task.id,
            },
        };
    }

    return {
        query: {
            task: task.id,
        },
    };
}

export function validateTaskFields(task: ITask): Record<string, string> | null {
    if (task.type === TaskType.Goal) {
        return {};
    }

    let formErrors: Record<string, string> | null = null;

    if (!task.title) {
        formErrors ??= {};
        formErrors.title = vueI18n.global.t('field-required');
    }

    if (!task.deadline) {
        formErrors ??= {};
        formErrors.deadline = vueI18n.global.t('field-required');
    }

    if (!task.description) {
        formErrors ??= {};
        formErrors.description = vueI18n.global.t('field-required');
    }

    if (!task.collaborators?.some((collaborator) => collaborator.role === CollaboratorRole.Assignee)) {
        formErrors ??= {};
        formErrors.collaborators = vueI18n.global.t('field-required');
    }

    return formErrors;
}

export function validateGoalFields(goal: ITask): Record<string, string> | null {
    if (goal.type !== TaskType.Goal) {
        return {};
    }

    let formErrors: Record<string, string> | null = null;

    if (!goal.title) {
        formErrors ??= {};
        formErrors.title = vueI18n.global.t('field-required');
    }

    if (!goal.description) {
        formErrors ??= {};
        formErrors.description = vueI18n.global.t('field-required');
    }

    return formErrors;
}

export function useTaskApi() {
    return api;
}

export function useTaskActions(
    task: Ref<ITask>,
    validate?: (task: ITask, callback?: () => unknown) => void,
    showApproverSelector?: (task: ITask) => void,
) {
    const contextMenu = useTaskContextMenu(validate, showApproverSelector);

    const editable = computed((): boolean => {
        return task.value.status === Status.Draft;
    });
    const disabled = computed((): boolean => {
        return (
            !!task.value.archivedAt ||
            task.value.status === Status.Completed ||
            task.value.status === Status.Rejected ||
            task.value.status === Status.Finished
        );
    });

    const actions = computed(() => contextMenu.getTaskActionsImpl(task.value, disabled.value, editable.value));

    function openContextMenu(event: MouseEvent) {
        contextMenu.openContextMenuImpl(event, task.value, actions.value);
    }

    function confirmToDraft() {
        contextMenu.confirmToDraft(task.value);
    }

    return { actions, editable, disabled, ...contextMenu, openContextMenu, confirmToDraft };
}

export function useTaskChat(task: Ref<ITask>) {
    const newComment = ref({
        id: -1,
        text: '',
        attachments: [],
    } as IChatComment);
    const editComment = ref(null as IChatComment | null);
    const storyViewMode = computed(Storages.Settings.computed(Settings.UI.StoryViewMode, StoryViewMode.All));

    function stopEdit(): void {
        editComment.value = null;
    }

    function startEdit(story?: IStory): void {
        if (story && story.type === StoryType.Comment && task.value.stories?.indexOf(story) !== -1) {
            editComment.value = {
                id: story.id,
                text: story.text,
                attachments: [],
            };
        }

        if (editComment.value || !task.value.stories || task.value.stories.length === 0) {
            return;
        }

        const lastStory = findLast(
            task.value.stories,
            (story) => story.type === StoryType.Comment && story.actorId === store.state.user?.id,
        );

        if (!lastStory) {
            return;
        }

        editComment.value = {
            id: lastStory.id,
            text: lastStory.text,
            attachments: [],
        };
    }

    function updateComment(comment: IChatComment): void {
        if (comment.text) {
            api.updateCommentAsync(task.value, comment.id, comment.text);
        }
        editComment.value = null;
    }

    function createComment(comment: IChatComment): void {
        if (comment.text || comment.attachments?.length > 0) {
            api.createCommentAsync(task.value, comment);
        }
    }

    function uploadedAttachmentHandler(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>): void {
        if (!uploadable.model) {
            return;
        }

        api.createAttachmentAsync(task.value, {
            fileName: uploadable.name,
            objectName: uploadable.model.data.objectName,
            extension: uploadable.extension,
            downloadUri: uploadable.model.data.downloadUri,
        } as IAttachment);
    }

    function removedAttachmentHandler(attachment: IAttachment): void {
        api.removeStoryAsync(task.value, {
            id: attachment.id,
        } as IStory);
    }

    return {
        newComment,
        editComment,
        storyViewMode,

        stopEdit,
        startEdit,
        updateComment,
        createComment,
        uploadedAttachmentHandler,
        removedAttachmentHandler,
    };
}

export function useTaskRights(task: Ref<ITask>) {
    const canUpdate = computed((): boolean => {
        return AuthorizationProvider.authorize(task.value, Operations.UpdateTask);
    });

    const canUpdateAuthor = computed((): boolean => {
        return AuthorizationProvider.authorize(task.value, Operations.UpdateTaskAuthor);
    });

    const canComment = computed((): boolean => {
        return AuthorizationProvider.authorize(task.value, Operations.Comment);
    });

    const canManageCollaborators = computed((): boolean => {
        return (
            AuthorizationProvider.authorize(task.value, Operations.AddCollaborator) &&
            AuthorizationProvider.authorize(task.value, Operations.RemoveCollaborator)
        );
    });

    return {
        canUpdate,
        canComment,
        canUpdateAuthor,
        canManageCollaborators,
    };
}

export function useTaskContextMenu(
    validate?: (task: ITask, callback?: () => unknown) => void,
    showApproverSelector?: (task: ITask) => void,
) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    validate ??= (task: ITask, callback?: () => void) => {
        const error = task.type === TaskType.Goal ? validateGoalFields(task) : validateTaskFields(task);

        if (error && task.type === TaskType.Goal) {
            router.push({
                name: 'goals.view',
                params: { goalId: task.id },
                query: {
                    action: 'validate',
                },
            });
            return;
        }

        if (error && task.type !== TaskType.Goal) {
            router.push({
                query: {
                    task: task.id,
                    prevTask: task.parentId,
                    prevSelected: route.query.selected,
                    action: 'validate',
                },
            });
            return;
        }

        callback?.();
    };
    showApproverSelector ??= (task: ITask) => {
        if (task.type === TaskType.Goal) {
            router.push({
                name: 'goals.view',
                params: { goalId: task.id },
                query: {
                    action: 'select-approvers',
                },
            });
            return;
        }

        router.push({
            query: {
                task: task.id,
                prevTask: task.parentId,
                prevSelected: route.query.selected,
                action: 'select-approvers',
            },
        });
    };

    function confirmToDraft(task: ITask, event?: { callback?: () => void }) {
        $confirm({
            message: t('confirm-task-edit', [t(getTaskTypeString(task), 2)]),
            buttonOkName: t('edit'),
            okCallback: () => api.toDraftAsync(task, event),
        });
    }

    function confirmSimpleAction(
        title: string,
        action: () => void,
        message = '',
        options: Partial<ConfirmContext> | null = null,
    ): void {
        $confirm({
            title,
            message,
            buttonOkName: t('yes'),
            okCallback: action,
            ...options,
        });
    }

    function getTaskActionsImpl(task: ITask, disabled: boolean, editable: boolean) {
        const taskTypeString = task.type === TaskType.Goal ? 'goal' : 'task';

        const approvement = task.approvements?.find(
            (approvement) =>
                approvement.status === ApprovementStatus.Waiting &&
                approvement.approverId === store.state.user?.id &&
                AuthorizationProvider.authorize(approvement, Operations.ApproveTask),
        );

        const actions: ITaskAction[][] = [
            [
                {
                    show: task.status === Status.Approvement && !!approvement,
                    icon: markRaw(CheckSvg),
                    title: t('approve'),
                    action: () =>
                        $confirm({
                            title: t('confirm-task-or-goal-approve'),
                            message: '',
                            withComment: true,
                            commentHint: t('confirm-task-or-goal-approve-hint'),
                            commentRequired: false,
                            commentMaxLength: 512,
                            buttonOkName: t('approve'),
                            buttonOkClasses: 'button--green',
                            okCallback: (comment) => {
                                if (!approvement) {
                                    return;
                                }

                                approvement.status = ApprovementStatus.Approved;
                                api.approveAsync(task, { approvement, comment });
                            },
                        }),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show: task.status === Status.Approvement && !!approvement,
                    icon: markRaw(TimesSvg),
                    title: t('disapprove'),
                    action: () =>
                        $confirm({
                            title: t('confirm-task-or-goal-disapprove'),
                            message: '',
                            withComment: true,
                            commentRequired: true,
                            commentMaxLength: 512,
                            buttonOkName: t('disapprove'),
                            okCallback: (comment) => {
                                if (!approvement) {
                                    return;
                                }

                                approvement.status = ApprovementStatus.Disapproved;
                                api.disapproveAsync(task, { approvement, comment });
                            },
                        }),
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: true,
                },
            ],
            [
                {
                    show:
                        !disabled &&
                        task.status === Status.Draft &&
                        AuthorizationProvider.authorize(task, Operations.StartTaskApprovementProcess),
                    icon: markRaw(NotesCheckedSvg),
                    title: t('send-for-approvement'),
                    // eslint-disable-next-line
                    action: () => validate?.(task, () => showApproverSelector?.(task)),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        !disabled &&
                        task.status === Status.Approvement &&
                        AuthorizationProvider.authorize(task, Operations.StartTaskApprovementProcess),
                    icon: markRaw(NotesCheckedSvg),
                    title: t('resend-for-approvement'),
                    // eslint-disable-next-line
                    action: () => showApproverSelector?.(task),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: !approvement,
                },
                {
                    show:
                        !disabled &&
                        task.status === Status.InProgress &&
                        AuthorizationProvider.authorize(task, Operations.CompleteTask),
                    icon: markRaw(CompleteSvg),
                    title: t('complete'),
                    action: () =>
                        confirmSimpleAction(
                            t(`confirm-${taskTypeString}-complete`),
                            () => api.completeAsync(task),
                            '',
                            {
                                buttonOkClasses: 'button--green',
                                commentMaxLength: 512,
                            },
                        ),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        task.status === Status.Completed &&
                        AuthorizationProvider.authorize(task, Operations.FinishTask),
                    icon: markRaw(CheckSvg),
                    title: t('finish'),
                    action: () =>
                        confirmSimpleAction(t(`confirm-${taskTypeString}-finish`), () => api.finishAsync(task), '', {
                            buttonOkClasses: 'button--green',
                        }),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        task.status === Status.Completed &&
                        AuthorizationProvider.authorize(task, Operations.ReturnTaskToInProgress),
                    icon: markRaw(TimesSvg),
                    title: t('return-to-in-progress'),
                    action: () =>
                        $confirm({
                            title: t(`confirm-${taskTypeString}-to-in-progress`),
                            message: '',
                            withComment: true,
                            commentHint: t(`confirm-task-or-goal-to-in-progress-hint`),
                            commentRequired: false,
                            commentMaxLength: 512,
                            buttonOkName: t('yes'),
                            okCallback: (comment) => api.toInProgressAsync(task, comment),
                        }),
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: true,
                },
                {
                    show: true,
                    icon: markRaw(ShareSvg),
                    title: t('copy-link'),
                    action: () => {
                        const uri = window.location.origin + router.resolve(getTaskRouteLocation(task)).fullPath;
                        copyTextToClipboard(uri);
                    },
                    panelClasses: 'button--secondary button--green',
                    isMainAction: false,
                },
            ],
            [
                {
                    show: !editable && !disabled && AuthorizationProvider.authorize(task, Operations.UpdateTask),
                    icon: markRaw(EditSvg),
                    title: t('edit'),
                    action: () => confirmToDraft?.(task),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
                {
                    show:
                        editable &&
                        !!task.savePointsCount &&
                        AuthorizationProvider.authorize(task, Operations.RollbackChanges),
                    icon: markRaw(RollbackSvg),
                    title: t('rollback-changes'),
                    action: () =>
                        validate?.(task, () =>
                            confirmSimpleAction(t(`confirm-rollback-changes`), () => api.rollbackChangesAsync(task)),
                        ),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
                {
                    show: !task.archivedAt && AuthorizationProvider.authorize(task, Operations.ArchiveTask),
                    icon: markRaw(ArchiveSvg),
                    title: t('archive'),
                    action: () =>
                        confirmSimpleAction(
                            t(`confirm-${taskTypeString}-archiving`),
                            () => api.archiveAsync(task),
                            t(`confirm-${taskTypeString}-archiving-hint`),
                        ),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
            ],
        ];

        return actions.reduce((carry, actions) => {
            actions = actions.filter((action) => action.show);

            if (!carry.length) {
                return actions;
            }

            if (actions.length) {
                carry = [
                    ...carry,
                    {
                        show: true,
                        title: '',
                        action: () => 0,
                        classes: 'button__dropdown-option--separator',
                        isMainAction: false,
                    },
                    ...actions,
                ];
            }

            return carry;
        }, [] as ITaskAction[]);
    }

    function openContextMenuImpl(event: MouseEvent, task: ITask, actions: ITaskAction[]): void {
        //prevent the browser's default menu
        event.preventDefault();

        const defaultAction: MenuItem[] = [
            {
                icon: h(OpenSvg),
                label: t('open'),
                onClick: () => {
                    const route = getTaskRouteLocation(task);
                    router.push(route);
                },
            },
            {
                icon: h(OpenSvg),
                label: t('open-in-new-tab'),
                onClick: () => {
                    const uri = window.location.origin + router.resolve(getTaskRouteLocation(task)).fullPath;

                    window.open(uri, '_blank')?.focus();
                },
            },
            {
                divided: 'self',
            },
        ];

        ContextMenu.showContextMenu({
            x: event.x,
            y: event.y,
            zIndex: 100000,
            customClass: 'prevent-close',

            items: defaultAction.concat(
                actions.map((option): MenuItem => {
                    if (option.classes && option.classes.indexOf('button__dropdown-option--separator') !== -1) {
                        return {
                            divided: 'self',
                        };
                    }

                    return {
                        icon: option.icon ? h(option.icon) : undefined,
                        label: option.title,
                        customClass: option.classes,
                        onClick: (event) => option.action?.(option, event),
                    };
                }),
            ),
        });
    }

    function getTaskActions(task: ITask) {
        const editable = task.status === Status.Draft;
        const disabled =
            !!task.archivedAt ||
            task.status === Status.Completed ||
            task.status === Status.Rejected ||
            task.status === Status.Finished;

        return getTaskActionsImpl(task, disabled, editable);
    }

    function openContextMenu(event: MouseEvent, task: ITask) {
        const actions = getTaskActions(task);
        openContextMenuImpl(event, task, actions);
    }

    return {
        validate,
        showApproverSelector,

        confirmToDraft,
        confirmSimpleAction,

        getTaskActions,
        openContextMenu,

        getTaskActionsImpl,
        openContextMenuImpl,
    };
}
