<template>
    <ul class="file-list">
        <template v-for="(attachment, attachmentIndex) in attachments" :key="attachmentIndex">
            <li class="file-list__item">
                <VAttachment
                    nowrap
                    removable
                    :attachment="attachment"
                    @removed="$emit('removed', attachment)"
                ></VAttachment>
            </li>
        </template>

        <template v-for="(uploadable, uploadableIndex) in uploadables" :key="uploadableIndex">
            <li class="file-list__item">
                <VAttachment
                    nowrap
                    removable
                    :uploadable="uploadable"
                    @removed="abortUploading(uploadable)"
                ></VAttachment>
            </li>
        </template>

        <li class="file-list__item" v-if="!hideAddButton">
            <div class="attachment attachment--upload">
                <PlusSvg></PlusSvg>

                <small class="attachment__hint" v-if="fileSizeLimitString">
                    {{ t('file-size-limit-hint', { limit: fileSizeLimitString }) }}
                </small>

                <button
                    class="attachment__button attachment__button--borderless attachment__button--upload"
                    @click="openFileSelectionWindow"
                ></button>

                <input ref="input" type="file" hidden @change="fileSelectedHandler" />
            </div>
        </li>
    </ul>
</template>

<script setup lang="ts">
// Svg
import PlusSvg from '@/assets/plus.svg';

// Components
import VAttachment from './VAttachment.vue';

// Other
import IAttachment from '@/core/Values/IAttachment';
import IUploader from '@/core/Uploader/IUploader';
import UploadableFile from '@/core/Uploader/UploadableFile';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { filesize } from 'filesize';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import { PropType, ref, defineProps, computed, watch, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['uploaded', 'removed']);
const props = defineProps({
    uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
    attachments: { type: Array as PropType<IAttachment[]>, required: true, default: () => [] },
    uploadables: {
        type: Array as PropType<UploadableFile<IApiResult<IObjectStoreModel>>[]>,
        default: () => [],
    },
    queue: { type: Array as PropType<File[]>, default: () => [] },
    nowrap: { type: Boolean, default: false },
    hideAddButton: { type: Boolean, default: false },
});

let input = ref(null as HTMLInputElement | undefined | null);
let queue = ref(props.queue);
let uploadables = ref(props.uploadables);
let attachments = ref(props.attachments);

const fileSizeLimitString = computed(() => {
    if (!props.uploader.fileSizeLimit) {
        return '';
    }

    return filesize(props.uploader.fileSizeLimit, { standard: 'jedec' });
});

watch(
    () => props.queue,
    () => {
        queue.value = props.queue;
    },
);

watch(
    () => props.uploadables,
    () => {
        uploadables.value = props.uploadables;
    },
);

watch(
    () => props.attachments,
    () => {
        attachments.value = props.attachments;
    },
);

watch(
    () => queue.value,
    () => {
        if (queue.value?.length === 0) {
            return;
        }

        let file = queue.value.pop();
        while (file) {
            const uploadable = ref(new UploadableFile<IApiResult<IObjectStoreModel>>(file));

            uploadables.value.push(uploadable.value);
            props.uploader
                .upload(uploadable.value)
                .then((uploadable) => {
                    if (uploadable.status === UploadableFileStatus.UPLOADED) {
                        const index = uploadables.value.indexOf(uploadable);

                        if (index !== -1) {
                            uploadables.value.splice(index, 1);
                        }

                        emit('uploaded', uploadable);
                    }
                })
                .catch(() => null);

            // eslint-disable-next-line vue/no-mutating-props
            file = queue.value.pop();
        }
    },
    { deep: true },
);

function openFileSelectionWindow() {
    if (input.value) {
        input.value.click();
        input.value.value = '';
    }
}

function fileSelectedHandler() {
    if (input && input.value?.files) {
        for (let index = 0; index < input.value.files.length; index++) {
            const file = input.value.files.item(index);

            if (!file) {
                continue;
            }

            queue.value.push(file);
        }
    }
}

function abortUploading(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>) {
    uploadable.controller?.abort();

    const index = uploadables.value.indexOf(uploadable);
    if (index !== -1) {
        uploadables.value.splice(index, 1);
    }
}
</script>

<style lang="scss">
.file-list {
    display: flex;
    gap: 1rem 0.75rem;
    flex-wrap: wrap;
    margin: 0;

    &__item {
        height: 4.5rem;
        min-width: 4.5rem;
        max-width: 15rem;

        &--picture {
            max-width: 6rem;
        }
    }

    &--nowrap {
        flex-wrap: nowrap;
    }
}
</style>
