<template>
    <div
        class="attachment"
        :class="{
            'attachment--preview': isBrowsable,
        }"
        v-tooltip="generalHint"
    >
        <template v-if="uploadable">
            <div class="attachment__uploader">
                <span class="attachment__filename" :class="{ 'attachment__filename--nowrap': nowrap }">{{
                    uploadable.name
                }}</span>

                <div class="attachment__progressbar">
                    <div
                        class="attachment__progressbar-inner"
                        :class="{
                            'attachment__progressbar-inner--error': isError,
                        }"
                        :style="{
                            width: uploadable.progress + '%',
                        }"
                    ></div>
                </div>

                <span class="attachment__error" v-if="uploadable.errorMessage">
                    {{ uploadable.errorMessage }}
                </span>
            </div>
        </template>

        <template v-else-if="attachment">
            <template v-if="isBrowsable">
                <img class="attachment__picture" :src="attachment.downloadUri" />
            </template>

            <template v-else>
                <div class="attachment__picture attachment__picture--default">
                    <UnknownFileSvg></UnknownFileSvg>
                </div>

                <span class="attachment__filename" :class="{ 'attachment__filename--nowrap': nowrap }">
                    {{ attachment.fileName }}
                </span>
            </template>

            <button
                class="attachment__button attachment__button--borderless attachment__button--download"
                @click="open"
                v-if="isBrowsable"
            >
                <EyeSvg></EyeSvg>
            </button>

            <a
                class="attachment__button attachment__button--borderless attachment__button--download"
                :href="attachment.downloadUri"
                :download="attachment.fileName"
                v-else
            >
                <DownloadSvg></DownloadSvg>
            </a>
        </template>

        <button
            class="attachment__button attachment__button--remove"
            @click="$emit('removed', attachment ?? uploadable)"
            v-if="removable && canDelete"
        >
            <TimesSvg></TimesSvg>
        </button>
    </div>
</template>

<script setup lang="ts">
// Svg
import EyeSvg from '@/assets/eye.svg';
import TimesSvg from '@/assets/times.svg';
import DownloadSvg from '@/assets/download.svg';
import UnknownFileSvg from '@/assets/unknown-file.svg';

// Components
// ...

// Other
import IAttachment from '@/core/Values/IAttachment';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { $showImage } from '@/utils/app-utils';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import { computed, defineProps, PropType } from 'vue';

const browsableExtensions = ['.jpg', '.jpeg', '.png', '.svg', '.tiff', '.webp'];

const props = defineProps({
    attachment: { type: Object as PropType<IAttachment | null>, default: null },
    uploadable: { type: Object as PropType<UploadableFile<IApiResult<IObjectStoreModel>> | null>, default: null },
    removable: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
});

const isError = computed((): boolean => {
    return props.uploadable?.status === UploadableFileStatus.ERROR;
});

const isBrowsable = computed((): boolean => {
    if (!props.attachment) {
        return false;
    }

    return browsableExtensions.includes(props.attachment.extension);
});

const generalHint = computed((): string => {
    if (props.attachment) {
        return props.attachment.fileName;
    }

    if (props.uploadable) {
        return props.uploadable?.errorMessage ? props.uploadable.errorMessage : props.uploadable.name;
    }

    return '';
});

const canDelete = computed((): boolean => {
    if (props.uploadable) {
        return true;
    }

    if (props.attachment && props.attachment.authorId) {
        return AuthorizationProvider.authorize(props.attachment, Operations.DeleteStory);
    }

    return false;
});

function open() {
    if (!props.attachment) {
        return;
    }

    $showImage(props.attachment);
}
</script>

<style lang="scss">
.attachment {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
    padding: 0.75rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px dashed var(--background-overlay, rgba(0, 0, 0, 0.3));

    &--upload {
        flex-direction: column;

        & svg {
            width: 1.5rem;
            height: 1.5rem;
            flex-shrink: 0;
            margin-bottom: 0.5rem;
        }
    }

    &--preview {
        padding: 0;
        border: none;
    }

    &__hint {
        @include caption-quaternary();
    }

    &__picture {
        width: 100%;
        height: 100%;
        flex-shrink: 0;

        object-fit: cover;
        border-radius: 0.5rem;

        &--default {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.25rem;
        }
    }

    &__button {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
        position: absolute;

        color: transparent;
        border: 0;
        cursor: pointer;
        text-align: center;
        background-color: var(--background-color);
        --background-color: transparent;

        &--borderless {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
        }

        &--download {
            border-radius: 0.5rem;

            & svg {
                width: 1.5rem;
                height: 1.5rem;
            }

            &:hover {
                color: white;
                background: linear-gradient(
                    0deg,
                    var(--background-overlay, rgba(0, 0, 0, 0.3)) 0%,
                    var(--background-overlay, rgba(0, 0, 0, 0.3)) 100%
                );
            }
        }

        &--remove {
            top: -0.375rem;
            right: -0.375rem;
            margin: -0.25rem;
            padding: 0.25rem;

            color: var(--text-black-primary);

            & svg {
                width: 1rem;
                height: 1rem;

                border-radius: 50%;
                background-color: var(--background-color);
                --background-color: var(--background-tertiary);
            }

            &:hover {
                color: var(--color-negative);
            }
        }
    }

    &__filename {
        @include caption-tertiary();
        color: var(--text-black-primary);

        &--nowrap {
            @include overflow-ellipsis();
        }
    }

    &__progressbar {
        height: 0.375rem;
        border-radius: 0.5rem;
        margin-top: 0.375rem;
        background: var(--background-color);
        --background-color: var(--background-tertiary);
    }

    &__progressbar-inner {
        height: 100%;
        border-radius: 0.5rem;
        transition: width 1s ease-in-out;
        background: var(--background-color);
        --background-color: var(--brand-green);

        &--error {
            background: var(--background-color);
            --background-color: var(--color-negative);
        }
    }

    &__uploader {
        display: flex;
        min-width: 0; // https://stackoverflow.com/a/66689926
        flex-direction: column;
    }

    &__error {
        @include caption-quaternary();
        @include overflow-ellipsis();
        margin-top: 0.25rem;

        color: var(--color-negative);
    }
}
</style>
