<template>
    <VPage :class="{ 'app-page--fullscreen': pageSettings.fullscreen }">
        <VPageHeader :title="t('approvements')" show-third-block>
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VPageSettings class="prevent-close" :options="pageOptions" v-model="pageSettings"></VPageSettings>

                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Approver,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VButtonDropdown class="prevent-close" placement="left-start" :options="regularActions">
                        <DotsSvg class="rotate-90"></DotsSvg>
                    </VButtonDropdown>

                    <VSearchField class="prevent-close" debounce-mode v-model="searchString"></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent :vertical="pageSettings.viewMode === 'table'">
            <VBoard v-if="pageSettings.viewMode === 'board'">
                <VColumn
                    wheel-propagation
                    :column="{
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: t('tasks'),
                    }"
                >
                    <ul class="column__list">
                        <template v-for="task in tasks" :key="task.id">
                            <li class="column__item">
                                <RouterLink :to="{ query: { task: task.id } }">
                                    <VTaskCard :task="task"></VTaskCard>
                                </RouterLink>
                            </li>
                        </template>
                    </ul>
                </VColumn>

                <VColumn
                    wheel-propagation
                    class="column--goals"
                    :column="{
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: t('goals'),
                    }"
                >
                    <ul class="column__list">
                        <template v-for="goal in goals" :key="goal.id">
                            <li class="column__item">
                                <RouterLink :to="{ name: 'goals.view', params: { goalId: goal.id } }">
                                    <VGoalCard :goal="goal"></VGoalCard>
                                </RouterLink>
                            </li>
                        </template>
                    </ul>
                </VColumn>
            </VBoard>

            <VTable
                :resetOrder="resetOrder"
                v-model:table="table"
                v-model:order="order"
                v-else-if="pageSettings.viewMode === 'table'"
            >
                <VTableDataSet :title="t('tasks')" v-if="tasks.length">
                    <template v-for="(task, index) in tasks" :key="task.id">
                        <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                            <VTableRow
                                :table="table"
                                :data="task"
                                :index="index"
                                :active="task.id.toString() == route.query.task"
                                @contextmenu="openContextMenu($event, task)"
                            ></VTableRow>
                        </RouterLink>
                    </template>
                </VTableDataSet>

                <VTableDataSet :title="t('goals')" v-if="goals.length">
                    <template v-for="(goal, index) in goals" :key="goal.id">
                        <RouterLink :to="{ name: 'goals.view', params: { goalId: goal.id } }">
                            <VTableRow
                                :table="table"
                                :data="goal"
                                :index="index"
                                :active="goal.id.toString() == route.query.task"
                                @contextmenu="openContextMenu($event, goal)"
                            ></VTableRow>
                        </RouterLink>
                    </template>
                </VTableDataSet>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VTaskCard from '../components/VTaskCard.vue';
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';
import VTable from '../components/VTable.vue';
import VTableRow from '../components/VTableRow.vue';
import VTableDataSet from '../components/VTableDataSet.vue';
import VPageSettings from '../components/VPageSettings.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { ExportTaskRequest, QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import UserMapper from '@/core/UserMapper';
import Status from '@/core/Values/Status';
import TaskType from '@/core/Values/TaskType';
import { Raw, markRaw, ref } from 'vue';
import { TaskMutatorContext, TaskMutator } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { defineComponent } from 'vue';
import IApprovement from '@/core/Models/IApprovement';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import { useI18n } from 'vue-i18n';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { useRoute, useRouter } from 'vue-router';
import { IOrder } from '@/core/Values/IOrder';
import IPageSettings from '@/core/Values/IPageSettings';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { defaultColumns } from '@/mixins/TaskTableViewer';
import { usePageOptions } from '@/mixins/PageSettings';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { downloadFile } from '@/utils/utils';
import TaskExportSchema from '@/core/Values/TaskExportSchema';

class ApprovementTaskMutator extends TaskMutator {
    protected async createApprovement(
        context: TaskMutatorContext,
        approvement: IApprovement,
    ): Promise<ITask | undefined> {
        const updatedTask = await super.createApprovement(context, approvement);

        if (approvement.approverId === store.state.user?.id && !updatedTask) {
            const tasks = await TaskService.queryAsync({
                whereId: [approvement.taskId as number],
                whereStatus: [Status.Approvement],
                whereApprovementRequired: true,
                includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
                perPage: 1,
            });

            if (tasks.length) {
                context.tasks.push(tasks[0]);
                return tasks[0];
            }
        }

        return updatedTask;
    }
}

const approvementTaskMutator = markRaw(new ApprovementTaskMutator());

function resetOrder() {
    return {
        orderBy: 'id',
        orderAscending: false,
    };
}

export default defineComponent({
    components: {
        DotsSvg,

        VTaskCard,
        VGoalCard,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VBoard,
        VColumn,
        VTable,
        VTableRow,
        VTableDataSet,
        VPageSettings,
        VButtonDropdown,
    },

    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const contextMenu = useTaskContextMenu();
        const table = useTaskTableViewer(Settings.UI.Table + '.user.approvements', {
            header: true,
            columns: defaultColumns,
        });
        const defaultPageOptions = usePageOptions();
        const pageOptions = [defaultPageOptions.viewMode, defaultPageOptions.fullscreen];

        return {
            t,
            route,
            router,

            table,
            pageOptions,
            ...contextMenu,

            searchString: ref(''),
            tasksAndGoals: ref([] as ITask[]),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        order: Storages.Filters.computed<IOrder>(Settings.UI.Order + '.user.approvements', resetOrder()),
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.approvements', {
            value: [],
            formattedValue: {},
        }),
        pageSettings: Storages.Settings.computed<IPageSettings>(Settings.UI.PageSettings + '.user.approvements', {
            viewMode: 'board',
            fullscreen: false,
        }),

        regularActions(): IDropdownOption[] {
            return [
                {
                    icon: markRaw(DownloadSvg),
                    title: this.t('export'),
                    action: () => {
                        downloadFile('/api/v1/tasks/export', {
                            ...this.tasksFilters,
                            withColumns: this.table.columns
                                .filter((column) => column.visible)
                                .map((column) => column.key),
                            schema: TaskExportSchema.List,
                            language: store.state.userSettings.locale,
                        } as ExportTaskRequest);
                    },
                },
            ];
        },

        filterKey(): string {
            return Settings.UI.Filters + '.' + (this.route.name?.toString() ?? 'shared');
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        tasks(): ITask[] {
            return this.tasksAndGoals.filter((task) => task.type !== TaskType.Goal);
        },

        goals(): ITask[] {
            return this.tasksAndGoals.filter((task) => task.type === TaskType.Goal);
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.order,
                ...this.filters.formattedValue,
                whereStatus: [Status.Approvement],
                whereApprovementRequired: true,
                includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
                search: this.searchString,
            };
        },
    },

    methods: {
        resetOrder,

        TaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }) {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },

        async fetchData(): Promise<void> {
            this.tasksAndGoals = await TaskService.queryAsync(this.tasksFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasksAndGoals);
            ObjectStorageMapper.mapTasksAsync(this.tasksAndGoals);

            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(
                    this.tasksAndGoals,
                    {
                        mapUsers: true,
                        mapPreview: true,
                        fetchTask: async (patch: Partial<ITask>) => {
                            if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                                return undefined;
                            }

                            const tasks = await TaskService.queryAsync({
                                ...this.tasksFilters,
                                whereId: [patch.id as number],
                                perPage: 1,
                            });

                            if (!tasks.length) {
                                return undefined;
                            }

                            return UserMapper.mapTaskAsync(tasks[0]);
                        },

                        // Excludes tasks if they are not matching the specified filters.
                        excludeTask: (task: ITask) =>
                            !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                        ignoreTaskCreating: () => true,
                    },
                    approvementTaskMutator, // Custom mutator
                ),
            );
            MutationBus.activate(this.mutatorContext);
        },
    },

    watch: {
        tasksFilters: 'fetchData',
    },

    created() {
        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted() {
        setPageTitle(this.t('approvements'));
    },

    beforeUnmount(): void {
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
</script>
