import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import VUserSelector from './VUserSelector.vue';

// Other
import IUser from '@/core/Models/IUser';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import { computed, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false, // used to prevent event (propagation) like (added, removed)
},
  __name: 'VCollaboratorsList',
  props: {
    collaborators: { type: Array as PropType<ICollaborator[]>, default: () => [] },
    role: { type: Number as PropType<CollaboratorRole>, required: true },
    taskId: { type: Number, required: true },
    title: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
},
  emits: [
    'added',
    'removed',
    'replaced',
    'update:collaborators',
    'update:collaborators',
    'edit-request',
],
  setup(__props, { emit: __emit }) {

// Svg

// Components


const { t } = useI18n();
const emit = __emit;
const props = __props;

const users = ref([] as IUser[]);

const titleText = computed(() => {
    switch (props.role) {
        case CollaboratorRole.Assignee:
            return t('assignees');
        case CollaboratorRole.Contributor:
            return t('contributors');
        case CollaboratorRole.Collaborator:
            return t('collaborators');
        default:
            return t('collaborators');
    }
});

function indexOf(user: IUser): number {
    return props.collaborators.findIndex((item) => item.userId === user.id && item.role === props.role);
}

function added(user: IUser) {
    const collaborator = mapCollaborator(user);

    emit('added', collaborator);
    emit('update:collaborators', [...props.collaborators, collaborator]);
}

function removed(user: IUser) {
    const collaborator = mapCollaborator(user);

    const index = indexOf(user);
    if (index !== -1) {
        emit('removed', collaborator);
        emit(
            'update:collaborators',
            props.collaborators.filter((_, i) => i === index),
        );
    }
}

function replaced(event: { oldUser: IUser; newUser: IUser }) {
    const oldCollaborator = mapCollaborator(event.oldUser);
    const newCollaborator = mapCollaborator(event.newUser);

    const index = indexOf(event.oldUser);
    if (index !== -1) {
        emit('replaced', { oldCollaborator, newCollaborator });
        emit('update:collaborators', [...props.collaborators.filter((_, i) => i === index), newCollaborator]);
    }
}

function mapCollaborator(user: IUser) {
    return {
        role: props.role,
        user,
        userId: user.id,
        taskId: props.taskId,
    };
}

function onCollaboratorsChanged() {
    users.value = props.collaborators
        .filter((collaborator) => collaborator.user && collaborator.role === props.role)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((collaborator) => collaborator.user!);
}

watch(() => props.role, onCollaboratorsChanged);
watch(() => props.collaborators, onCollaboratorsChanged, { deep: true, immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VUserSelector, {
    deletable: "",
    title: __props.title ? titleText.value : '',
    fluid: __props.fluid,
    multiple: __props.multiple,
    editable: __props.editable,
    disabled: __props.disabled,
    autofocus: __props.autofocus,
    onAdded: added,
    onRemoved: removed,
    onReplaced: replaced,
    onEditRequest: _cache[0] || (_cache[0] = ($event: any) => (emit('edit-request', $event))),
    modelValue: users.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((users).value = $event))
  }, null, 8, ["title", "fluid", "multiple", "editable", "disabled", "autofocus", "modelValue"]))
}
}

})