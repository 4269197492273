<template>
    <VUserSelector
        deletable
        :title="title ? titleText : ''"
        :fluid="fluid"
        :multiple="multiple"
        :editable="editable"
        :disabled="disabled"
        :autofocus="autofocus"
        @added="added"
        @removed="removed"
        @replaced="replaced"
        @edit-request="emit('edit-request', $event)"
        v-model="users"
    ></VUserSelector>
</template>

<script setup lang="ts">
// Svg

// Components
import VUserSelector from './VUserSelector.vue';

// Other
import IUser from '@/core/Models/IUser';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import { computed, defineOptions, PropType, ref, watch, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

defineOptions({
    inheritAttrs: false, // used to prevent event (propagation) like (added, removed)
});

const { t } = useI18n();
const emit = defineEmits([
    'added',
    'removed',
    'replaced',
    'update:collaborators',
    'update:collaborators',
    'edit-request',
]);
const props = defineProps({
    collaborators: { type: Array as PropType<ICollaborator[]>, default: () => [] },
    role: { type: Number as PropType<CollaboratorRole>, required: true },
    taskId: { type: Number, required: true },
    title: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
});

const users = ref([] as IUser[]);

const titleText = computed(() => {
    switch (props.role) {
        case CollaboratorRole.Assignee:
            return t('assignees');
        case CollaboratorRole.Contributor:
            return t('contributors');
        case CollaboratorRole.Collaborator:
            return t('collaborators');
        default:
            return t('collaborators');
    }
});

function indexOf(user: IUser): number {
    return props.collaborators.findIndex((item) => item.userId === user.id && item.role === props.role);
}

function added(user: IUser) {
    const collaborator = mapCollaborator(user);

    emit('added', collaborator);
    emit('update:collaborators', [...props.collaborators, collaborator]);
}

function removed(user: IUser) {
    const collaborator = mapCollaborator(user);

    const index = indexOf(user);
    if (index !== -1) {
        emit('removed', collaborator);
        emit(
            'update:collaborators',
            props.collaborators.filter((_, i) => i === index),
        );
    }
}

function replaced(event: { oldUser: IUser; newUser: IUser }) {
    const oldCollaborator = mapCollaborator(event.oldUser);
    const newCollaborator = mapCollaborator(event.newUser);

    const index = indexOf(event.oldUser);
    if (index !== -1) {
        emit('replaced', { oldCollaborator, newCollaborator });
        emit('update:collaborators', [...props.collaborators.filter((_, i) => i === index), newCollaborator]);
    }
}

function mapCollaborator(user: IUser) {
    return {
        role: props.role,
        user,
        userId: user.id,
        taskId: props.taskId,
    };
}

function onCollaboratorsChanged() {
    users.value = props.collaborators
        .filter((collaborator) => collaborator.user && collaborator.role === props.role)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((collaborator) => collaborator.user!);
}

watch(() => props.role, onCollaboratorsChanged);
watch(() => props.collaborators, onCollaboratorsChanged, { deep: true, immediate: true });
</script>
